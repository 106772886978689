var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title-bar'),_c('v-container',{attrs:{"grid-list-xl":"","fluid":"","pt-0":""}},[_c('app-card',{attrs:{"heading":_vm.$t('message.grid'),"contentCustomClass":"text-xs-center"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"theme--dark",attrs:{"color":"primary"}},[_c('v-card-text',[_vm._v("12")])],1)],1),_vm._l((3),function(ref){
var n = ref.n;
var index = ref.index;
return _c('v-flex',{key:index,attrs:{"xs4":""}},[_c('v-card',{staticClass:"white--text",attrs:{"color":"primary"}},[_c('v-card-text',[_vm._v("6")])],1)],1)}),_vm._l((4),function(ref){
var n = ref.n;
var index = ref.index;
return _c('v-flex',{key:index,attrs:{"xs3":""}},[_c('v-card',{staticClass:"theme--dark",attrs:{"color":"primary"}},[_c('v-card-text',[_vm._v("3")])],1)],1)}),_vm._l((12),function(ref){
var n = ref.n;
var index = ref.index;
return _c('v-flex',{key:index,attrs:{"xs3":"","md1":""}},[_c('v-card',{staticClass:"theme--dark",attrs:{"color":"primary"}},[_c('v-card-text',[_vm._v("1")])],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }